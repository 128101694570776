import { Injectable } from '@angular/core';
import { ActionApiService } from './action-api.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/states/app.state';
import { UpdateQuoteData } from '../store/actions/quote-data.actions';
import {
	selectDrivers,
	selectManualDrivers,
	selectManualVehicles,
	selectPersonalVehicles,
	selectQuoteData,
} from '../store/selectors/quote-data.selectors';
import { first } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';
import { UpdateInterviewMetadata } from '../store/actions/interview-metadata.actions';
import { selectInterviewMetadata } from '../store/selectors/interview-metadata.selector';
import { LobsEnum } from '../enums/lobs.enum';

@Injectable({
	providedIn: 'root',
})
export class DriversService {
	public editFilings = new Subject<string>();

	constructor(private actionApiService: ActionApiService, private store: Store<IAppState>) { }

	getPrimaryDriverData(Id: string, formValue: any) {
		const driverLicenseStatus = formValue.DriverLicenseStatus;
		const driverEmploymentIndustry = formValue.DriverEmploymentIndustry;
		const driverEducation = formValue.DriverEducation;
		const driverOccupationStr = formValue.DriverOccupationStr;

		delete formValue.DriverLicenseStatus;
		delete formValue.DriverEmploymentIndustry;
		delete formValue.DriverEducation;
		delete formValue.DriverOccupationStr;

		return {
			...formValue,
			CreditCheckPermission: true,
			YearsWithPriorCarrierAuto: 1,
			Drivers: [
				{
					FirstName: formValue.FirstName,
					LastName: formValue.LastName,
					DOB: formValue.DateOfBirth,
					Gender: formValue.PersonalLineGender,
					SequenceNum: 0,
					Id,
					MaritalStatus: formValue.MaritalStatus,
					DriverLicenseStatus: driverLicenseStatus,
					DriverEmploymentIndustry: driverEmploymentIndustry,
					DriverOccupationStr: driverOccupationStr,
					DriverEducation: driverEducation,
					DriverDateLicensed: null,
				},
			],
		};
	}

	// Handles lexis nexis response
	handlePrefillDataResponse(driversVehicles, drivers, vehicles) {
		let Drivers = [];
		let PersonalVehicles = [];
		let discoverDrivers = [];
		let discoverVehicles = [];

		if (drivers) {
			Drivers = drivers
				? drivers
					.filter((driver) => driver.FirstName && driver.LastName)
					.map((x) => {
						return { ...x, active: true };
					})
				: [];
		}

		if (vehicles) {
			PersonalVehicles = vehicles
				? vehicles
					.filter((vehicle) => vehicle.VIN || vehicle.ExternalVINPrefix)
					.map((x) => {
						return { ...x, enabled: true };
					})
				: [];
		}

		// Getting all the drivers and vehicles from lexis nexis
		if (driversVehicles !== null) {
			discoverDrivers = driversVehicles.Drivers
				? driversVehicles.Drivers.filter((driver) => driver.FirstName && driver.LastName && driver.Gender).map((x) => {
					return { ...x, active: false };
				})
				: [];
			discoverVehicles = driversVehicles.PersonalVehicles
				? driversVehicles.PersonalVehicles.filter((vehicle) => vehicle.VIN)
				: [];
		}

		//check if manual vehicles is inside personal vehicles - if it is, need to drop
		let manualVehicles = [];
		this.store
			.select(selectManualVehicles)
			.pipe(first())
			.subscribe((res) => {
				manualVehicles = res;
				if (vehicles) {
					manualVehicles = manualVehicles.filter((i) => !vehicles.map((i) => i.Id).includes(i.Id));
				}
			});

		let manualDrivers = [];
		this.store
			.select(selectManualDrivers)
			.pipe(first())
			.subscribe((res) => {
				manualDrivers = res;
				if (vehicles) {
					manualDrivers = manualDrivers.filter((i) => !Drivers.map((i) => i.Id).includes(i.Id));
				}
			});

		this.store.dispatch(
			UpdateQuoteData({
				data: {
					Drivers,
					discoverDrivers,
					manualDrivers,
					PersonalVehicles,
					discoverVehicles,
					manualVehicles,
				},
			})
		);
	}

	updatePolicyInfoWithInitialDriversData(formValue, selectedLobs?: string[]) {
		const prefillDataReceived = new Subject(); // new observable to subscribe after we finish updateApplication
		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				// Id is taken from primary driver or generated if primary driver doesn't exist in data
				const Id =
					quoteData.Drivers && quoteData.Drivers.length > 0
						? quoteData.Drivers.find((driver) => driver.SequenceNum === 0).Id
						: uuidv4();
				const data = this.getPrimaryDriverData(Id, formValue);
				this.actionApiService
					.updateApplication(data, selectedLobs)
					.pipe(first())
					.subscribe((policyDataResponse) => {
						combineLatest([
							this.store.select(selectManualDrivers),
							this.store.select(selectManualVehicles),
							this.store.select(selectDrivers),
							this.store.select(selectPersonalVehicles),
						])
							.pipe(first())
							.subscribe(([manualDrivers, drivers, personalVehicles]) => {
								// need to filter personal vehicls in case they are on manual vehicles store

								// deleting manual drivers which are inside policy data
								let filteredManualDrivers = manualDrivers.filter((driver) => {
									if (!policyDataResponse['data'].Drivers.map((driver) => driver.Id).includes(driver.Id)) {
										return { ...driver };
									}
								});

								//need to add all drivers which were on the policy data and removed, to manual drivers
								filteredManualDrivers = filteredManualDrivers.concat(
									drivers.filter((driver) => driver.active == false)
								);

								// every driver inside policy data should be active
								let PersonalDrivers = [...policyDataResponse['data'].Drivers].map((driver) => {
									return { ...driver, active: true };
								});

								let PersonaVehicles = policyDataResponse['data'].PersonalVehicles
									? [...policyDataResponse['data'].PersonalVehicles]
										.map((vehicle) => {
											return { ...vehicle, enabled: true };
										})
										.concat(personalVehicles.filter((i) => i.enabled == false))
									: [];

								// in case there are vehicles inside personal vehicles - need to filter manual vehicles
								formValue.Gender = formValue.PersonalLineGender;
								delete formValue.PersonalLineGender;
								formValue.DOB = formValue.DateOfBirth;
								delete formValue.DateOfBirth;

								this.store.dispatch(
									UpdateQuoteData({
										data: {
											...policyDataResponse['data'],
											Drivers: PersonalDrivers,
											manualDrivers: filteredManualDrivers,
											PersonalVehicles: PersonaVehicles,
										},
									})
								);
							});

						// when update is finished call to lexis nexis, if need to
						this.store
							.select(selectInterviewMetadata)
							.pipe(first())
							.subscribe((res) => {
								if (res.prefillCall && Array.isArray(selectedLobs) && selectedLobs.includes(LobsEnum.PERSONAL_AUTO)) {
									this.actionApiService
										.getVehicleAndDrivers()
										.pipe(first())
										.subscribe((driversVehicles: any) => {
											this.store.dispatch(UpdateInterviewMetadata({ data: { prefillCall: false } }));
											this.handlePrefillDataResponse(
												driversVehicles,
												policyDataResponse.data.Drivers,
												policyDataResponse.data.PersonalVehicles
											);

											prefillDataReceived.next(true);
										});
								} else {
									// //no need to call to lexis nexis
									prefillDataReceived.next(false);
								}
							});
					});
			});

		return prefillDataReceived;
	}

	//For the case driver is added to the policy data and is inside the discovered drivers
	//need to remove it from discovered drivers array
	removeDuplicatesDrivers() {
		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((data) => {
				let DriversPolictDataArray = data.Drivers.map((i) => i.FirstName + ' ' + i.LastName);
				let filteredDrivers = data.discoverDrivers.filter(
					(i) => !DriversPolictDataArray.includes(i.FirstName + ' ' + i.LastName)
				);
				this.store.dispatch(UpdateQuoteData({ data: { discoverDrivers: filteredDrivers } }));
			});
	}

	//For the case driver was added to the policy and it is inside missing drivers array
	//need to filter all the drivers inside the policy from the missing driver array
	removeDuplicatesMissingDrivers() {
		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((data) => {
				let DriversPolictDataArray = data.Drivers.map((i) => i.Id);
				let filteredMissingDrivers = data.missingDrivers.filter((i) => !DriversPolictDataArray.includes(i.Id));
				let filteredMissingProgressiveDrivers = data.additionalQuestionsProgressiveMissingDrivers.filter(
					(i) => !DriversPolictDataArray.includes(i.Id)
				);
				this.store.dispatch(
					UpdateQuoteData({
						data: {
							missingDrivers: filteredMissingDrivers,
							additionalQuestionsProgressiveMissingDrivers: filteredMissingProgressiveDrivers,
						},
					})
				);
			});
	}

	deleteDriverAndPersonalVehicles(data) {
		delete data['data'].PersonalVehicles;
		delete data['data'].Drivers;
		return data;
	}

	editFiling(Filings) {
		this.editFilings.next(Filings);
	}

	// Currently relevant only for Progressive Drivers
	updateDriverFieldsByFilings(driver) {
		const driverData = { ...driver };
		// driver.Filings should be DELETED from driver object if Filings.length === 0
		if (driverData.Filings?.length === 0) {
			delete driverData.Filings;
		}

		return {
			...driverData,
			// SR22OrFinancialResponsibilityStatement should be true if there are filings with SequenceNum !== -1 and false otherwise
			SR22OrFinancialResponsibilityStatement:
				driver.Filings?.filter((filingsItem) => filingsItem.SequenceNum !== -1).length > 0,
		};
	}
}
